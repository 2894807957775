import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, IconButton, Modal, Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { publicIpv4 } from "public-ip";
import React, { forwardRef, useEffect, useState } from "react";
import { browserName, browserVersion, isMobile, isTablet, osName } from "react-device-detect";
import PhoneInput, { Country as CountryCode, formatPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setupAxiosInterceptors } from "./errorInterceptor";

setupAxiosInterceptors();

const anoCorrente = new Date().getFullYear();
const projectVersion = process.env.APP_VERSION ?? "3.0.1";

interface ApiResponse {
  success: boolean;
  link: string;
  qrCodeBase64?: string;
  paisISOCode?: string;
}

interface RegistoLocalStorage {
  data: string;
  hora: string;
  telefone: string;
  qrcode: string;
}

const CustomPhoneInput = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
  <input
    {...props}
    ref={ref}
    style={{
      fontSize: "18px",
      padding: "10px",
      borderRadius: "10px",
      border: "1px solid #ccc",
      width: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }}
  />
));

const ShareButtons: React.FC = () => {
  const url = "https://abreZap.app.br";
  const title = "Achei este site muito útil para enviar mensagens no WhatsApp sem adicionar o número aos contatos.";

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={2}
      sx={{
        position: "fixed",
        bottom: "34px",
        left: 0,
        width: "100%",
        bgcolor: "background.paper",
        py: 2,
        textAlign: "center",
        boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <FacebookShareButton url={url} title={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TwitterShareButton url={url} title={title}>
        <XIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </Box>
  );
};

const App: React.FC = () => {
  const [phone, setPhone] = useState<string | undefined>();
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userIP, setUserIP] = useState<string>("");
  const [userCountry, setUserCountry] = useState<CountryCode | undefined>("BR");

  const getPlatform = () => {
    if (isMobile) return "Mobile";
    if (isTablet) return "Tablet";
    return "Desktop";
  };

  const userAgent = navigator.userAgent;
  const platform = getPlatform();

  const registrarNoLocalStorage = (telefone: string, qrcode: string | null) => {
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    const agora = new Date();
    const novoRegistro: RegistoLocalStorage = {
      data: agora.toLocaleDateString(),
      hora: agora.toLocaleTimeString(),
      telefone,
      qrcode: qrcode ?? "",
    };
    registros.push(novoRegistro);
    localStorage.setItem("historico", JSON.stringify(registros));
  };

  const atualizarLocalStorage = (telefone: string, qrcode: string) => {
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    const registroIndex = registros.findIndex((registro) => registro.telefone === telefone);

    if (registroIndex !== -1) {
      registros[registroIndex].qrcode = qrcode;
    } else {
      const agora = new Date();
      registros.push({
        data: agora.toLocaleDateString(),
        hora: agora.toLocaleTimeString(),
        telefone,
        qrcode,
      });
    }

    localStorage.setItem("historico", JSON.stringify(registros));
  };

  const telefoneJaRegistrado = (telefone: string): boolean => {
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    return registros.some((registro) => registro.telefone === telefone);
  };

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const ip = await publicIpv4();
        setUserIP(ip);
      } catch (error) {
        console.error("Erro ao obter o IP do usuário:");
      }
    };
    fetchIP();
  }, []);

  useEffect(() => {
    if (userIP) {
      const fetchCountry = async () => {
        try {
          const response: AxiosResponse<ApiResponse> = await axios.get("api/geoip", { params: { ip: userIP } });
          if (response.data.success) {
            setUserCountry((response.data.paisISOCode as CountryCode) || "BR");
          }
        } catch (error) {
          console.error("Erro ao obter o País do usuário:");
          setUserCountry("BR");
        }
      };
      fetchCountry();
    }
  }, [userIP]);

  const handleAbreZap = async () => {
    const telefoneFormatado = phone?.replace(/\D/g, "") ?? "";
    if (!telefoneJaRegistrado(telefoneFormatado)) {
      try {
        const response: AxiosResponse<ApiResponse> = await axios.post("api/send", {
          phone: telefoneFormatado,
          moreInfo: {
            ip: userIP,
            platform,
            os: osName,
            browser: browserName,
            navVer: browserVersion,
            isMobile,
            isTablet,
            agent: userAgent,
          },
        });
        if (response.data.success) {
          registrarNoLocalStorage(telefoneFormatado, null);
          window.open(response.data.link, "_self");
        }
      } catch {
        console.error("Erro desconhecido ao enviar requisição");
      }
    } else {
      window.open(`whatsapp://send?phone=${telefoneFormatado}&type=phone_number&app_absent=0`, "_self");
    }
  };

  const handleGerarQRCode = async () => {
    const telefoneFormatado = phone?.replace(/\D/g, "") ?? "";
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    const registroExistente = registros.find((registro) => registro.telefone === telefoneFormatado);

    if (registroExistente?.qrcode) {
      setQrCode(registroExistente.qrcode);
      setIsModalOpen(true);
    } else {
      try {
        const response: AxiosResponse<ApiResponse> = await axios.post("api/qrcode", {
          phone: telefoneFormatado,
          moreInfo: {
            ip: userIP,
            platform,
            os: osName,
            browser: browserName,
            navVer: browserVersion,
            isMobile,
            isTablet,
            agent: userAgent,
          },
        });
        if (response.data.success && response.data.qrCodeBase64) {
          setQrCode(response.data.qrCodeBase64);
          setIsModalOpen(true);
          atualizarLocalStorage(telefoneFormatado, response.data.qrCodeBase64);
        }
      } catch {
        console.error("Erro desconhecido ao gerar QRCode");
      }
    }
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          textAlign: "center",
          paddingBottom: "128px",
        }}
      >
        <Box flex="1">
          <ToastContainer position="top-center" draggable />
          <Typography variant="h5" color="textSecondary" gutterBottom sx={{ paddingTop: 2 }}>
            <strong>Conversar agora com ⤵</strong>
          </Typography>

          <PhoneInput
            placeholder="Digite o número de telefone"
            value={phone}
            onChange={setPhone}
            defaultCountry={userCountry}
            international
            countryCallingCodeEditable={false}
            inputComponent={CustomPhoneInput}
            style={{ marginBottom: 20, width: "100%" }}
          />

          <Box display="flex" justifyContent="center" gap={2}>
            <Button variant="contained" sx={{ bgcolor: "#4CAF50", "&:hover": { bgcolor: "#45a049" }, px: 3, py: 1 }} onClick={handleAbreZap} disabled={!phone}>
              abreZap
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: "#da4e0d", "&:hover": { bgcolor: "#c7470c" }, px: 3, py: 1 }}
              onClick={handleGerarQRCode}
              disabled={!phone}
            >
              gerar QRCode
            </Button>
          </Box>

          <Box id="texto-descricao" mt={4} color="textSecondary">
            <Typography variant="body2">
              <strong>abreZap</strong> é um serviço online <strong>GRÁTIS</strong> que permite iniciar um chat de WhatsApp sem adicionar o número aos contatos,
              inclusive para números internacionais. O código do país é reconhecido automaticamente, funcionando em qualquer dispositivo com acesso à internet e
              com o app oficial do{" "}
              <a href="https://www.whatsapp.com/download" target="_blank" rel="noopener noreferrer">
                WhatsApp
              </a>{" "}
              instalado.
            </Typography>
          </Box>
        </Box>
      </Container>

      <ShareButtons />

      <Box
        component="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          bgcolor: "background.paper",
          py: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © {anoCorrente} | abreZap.app.br | ver. {projectVersion}
        </Typography>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "90%",
            maxWidth: 300,
            mx: "auto",
          }}
        >
          <IconButton aria-label="close" onClick={closeModal} sx={{ position: "absolute", right: 8, top: 8, color: "grey.600" }}>
            <CloseIcon />
          </IconButton>

          {qrCode && <img src={qrCode} alt="QR Code" style={{ margin: "10px 0", maxWidth: "100%" }} />}

          <Typography id="modal-description" variant="h6" sx={{ fontSize: "1.2rem", color: "text.primary", lineHeight: "1.17", mt: 2, textAlign: "center" }}>
            Aponte a câmera do seu celular para abrir um chat de Whatsapp com o número <strong>{phone ? formatPhoneNumber(phone) : ""}</strong>.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default App;
